main {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  animation: pulse 1.5s infinite alternate;
}

p {
  font-size: 1.5em;
  margin-top: 0;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

/* footer {
    margin-top: 2em;
  } */