/* Animations.module.css */

@keyframes dropAnimation {
    from {
      top: 0;
    }
    to {
      /* This value will be dynamically updated in the component, placeholder for now */
      top: var(--final-top); 
    }
  }
  
  .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: dropAnimation 1s ease-out forwards;
    will-change: top;
  }
  