/* HappyHoli.module.css */
/* .body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #fff;
  position: relative;
} */

.background {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.layer {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: radial-gradient(violet,blue, aqua, lime, yellow, red);
  border-radius: 50%;
  mix-blend-mode: soft-light;
  animation: scaleChange 7s ease-in-out infinite;
  opacity: 0.6;
  overflow: hidden;
}

/* You would duplicate this .layer class with different animation durations and delays to simulate randomness */
.layer:nth-child(2) {
  animation: scaleChange 18s ease-in-out infinite;
}

.layer:nth-child(3) {
  animation: scaleChange 39s ease-in-out infinite;
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(1.1);
  }
}

.text {
  font-size: 9vw;
  color: white;
  -webkit-text-stroke: 1px black;
  text-shadow:
    3px 3px 0px rgba(0,0,0,0.2),
    -1px -1px 0px rgba(255,255,255,0.3); /* Adding depth with shadow */
  animation: pulse 1.5s infinite alternate;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  opacity: 0.9;
}

@keyframes scaleChange {
  0%, 100% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(2); /* Change this value for bigger or smaller scaling */
  }
}
